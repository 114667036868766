<template>
    <b-container id="house">
        <h2>Willkommen im Ahrenshooper Landhaus auf dem Fischland</h2>

        <b-row class="row">
          <b-col cols="12" sm="6">
            <div class="fewo-imageslider" v-if="!isTablet">
              <div class="">
                <slider activeGallery="landhaus"/>
              </div>
            </div>
            <lightbox activeGallery="landhaus" v-if="isTablet"/>
          </b-col>
          <b-col cols="12" sm="6">
            <p>Im reizvollen <b>Ortsteil Althagen</b> befindet sich das 1997 erbaute <b>reetgedeckte Ahrenshooper Landhaus</b>. Der Stil des Hauses ist ortstypisch -&nbsp;von der Fischländer Haustür mit ihren Schnitzereien bis zu den Gauben in der klassischen Form des Ochsenauges. Nur zehn Minuten vom <b>feinsandigen, steinfreien Ostseestrand</b> entfernt liegt die <b>Ferienwohnung </b>auf einem großzügigen Areal umgeben von Fliederbüschen und Heckenrosen. Der nahe Schilfgürtel des Boddens lädt zum <b>Wandern oder zu ausgiebigen Fahrradtouren</b> ein.
            </p>
            <p>Die untergehende Sonne genießen in angenehmer Atmosphäre unterm Reetdach, das heißt: <b>Erleben und Entspannen im Einklang mit der Natur.</b></p>
          </b-col>
        </b-row>

    </b-container>
</template>

<script>
  import { checkForTablet } from '~/assets/js/utils';
  import slider from "~/components/content/fewoSlider/slider.vue";
  import lightbox from "~/components/content/fewoLightbox/lightbox.vue";

  export default {
    components: {
      slider,
      lightbox
    },
    data() {
      return {
        isTablet: false,
      }
    },

    mounted() {
      let _scope = this;

      this.handleResponsiveBehaviour();

      window.addEventListener('resize',function () {
        setTimeout(function () {
          _scope.handleResponsiveBehaviour();
        }, 100);
      }, {passive: true});
    },

    methods: {
      handleResponsiveBehaviour () {
        this.isTablet = checkForTablet();
      }
    }
  }
</script>