<template>
  <div id="subpage">
    <div class="headerbackground"></div>
    <div id="wrap">
      <header404/>
      <b-container>
      <b-row>
        <b-col>
          <h2>Das gibt´s doch gar nicht!</h2>
          <p>Eines müssen wir Ihnen an dieser Stelle ganz offen gestehen: Sie suchen gerade nach einer Seite, die es leider gar nicht gibt, möglicherweise nie gegeben hat und unter Umständen auch nie geben wird. Warum Sie jetzt hier gelandet sind, hat einen der folgenden Gründe:</p>
          <ul class="list">
            <li>Der von Ihnen benutzte Link ist nicht mehr aktuell.</li>
            <li>Sie haben sie URL falsch eingebenen.</li>
            <li>Die Seite existiert nicht.</li>
          </ul>
          <p>Und nun? Bleiben Sie entspannt, versuchen Sie es später noch einmal oder besuchen Sie unsere <a href="/">Startseite.</a></p>
        </b-col>
      </b-row>
    </b-container>
    </div>
    <div class="pagebackground"></div>
  </div>
</template>

<script>
import header404 from "~/components/page/header/header-404";

export default {
  components: {
    header404,
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head () {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
  },
  data () {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    }
  }
}
</script>