<template>
  <b-container id="facility">
    <h2>Einrichtung der Ferienwohnung</h2>

    <b-row class="row">
      <b-col cols="12" sm="6">
        <div class="fewo-imageslider" v-if="!isTablet">
          <div class="">
            <slider activeGallery="fewo"/>
          </div>
        </div>
        <lightbox activeGallery="fewo" v-if="isTablet"/>
      </b-col>
      <b-col cols="12" sm="6">
        <p>Die zwei großzügig geschnittenen Räume sind liebevoll im Landhaus-Stil eingerichtet und bieten viel Platz für 2 Personen oder Familien mit Kleinkind. Ein Kachelofen schafft Wärme und Gemütlichkeit, nicht nur in der kalten Jahreszeit. Die nach Südwesten ausgerichtete Lage der Wohnung sorgt für viel Sonne und Licht von morgens bis spät abends. </p><ul><li>Ferienwohnung für 2 Personen und Kleinkind bis 4 Jahre,&nbsp;Nichtraucherwohnung, Wohnzimmer und Küche kombiniert, Flur, Schlafzimmer, Bad&nbsp;(ca. 67 qm)</li><li>Wohnzimmer mit Sofaecke und Tisch, Kachelofen,&nbsp;SAT-TV, Stereoanlage mit CD-Player</li><li>Küchenbereich mit Eßtisch, Kühlschrank, Geschirrspüler und Backofen</li><li>Schlafzimmer mit Doppelbett (180*200 cm)</li><li>Bad mit Dusche,&nbsp;Waschmaschine und Fön, Fußbodenheizung</li><li>Babystuhl und Kinderbett (120*60 cm)</li><li>PKW-Stellplatz und Fahrradschuppen</li><li>Süd-West-Terrasse mit großem Garten,&nbsp;Gartenmöbel und Relaxliegen</li><li>Haustiere auf Anfrage</li></ul>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { checkForTablet } from '~/assets/js/utils';
import slider from "~/components/content/fewoSlider/slider.vue";
import lightbox from "~/components/content/fewoLightbox/lightbox.vue";

export default {
  components: {
    slider,
    lightbox
  },
  data() {
    return {
      isTablet: false,
    }
  },

  mounted() {
    let _scope = this;

    this.handleResponsiveBehaviour();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        _scope.handleResponsiveBehaviour();
      }, 100);
    }, {passive: true});
  },

  methods: {
    handleResponsiveBehaviour () {
      this.isTablet = checkForTablet();
    }
  }
}
</script>