<template>
  <header id="header" ref="header">
    <div id="headerContent">
      <h1>Ferien im Landhaus</h1>
    </div>
  </header>
</template>

<script>

export default {
  components: {},

  data() {
    return {
    }
  },
}
</script>
