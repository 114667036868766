<template>
    <b-container id="location">
        <h2>Umgebung: Ostseebad Ahrenshoop, Altenhagen und Fischland</h2>

        <b-row class="row">
          <b-col cols="12" sm="6">
            <p>Das Ahrenshooper Landhaus liegt im Ortsteil Althagen in Ahrenshoop wenige Meter vom Hafen entfernt. Zum Sandstrand an der Ostsee sind es 5 Minuten und auch zum &nbsp;Supermarkt dauert es nicht länger.</p><ul class="linkedList"><li><a href="http://www.fahrgastschifffahrt-fischland-darss-zingst.de/" target="_blank" class="external-link-new-window">Boddenrundfahrten in den Nationalpark</a></li><li><a href="http://www.kutschfahrten-bergmann.de/" target="_blank" class="external-link-new-window">Reiten, Kutsch- und Kremserfahrten zum Darsser Ort</a></li><li><a href="http://www.kletterwald-darss.de/" target="_blank" class="external-link-new-window">Kletterwald Darß in Born</a></li><li><a href="http://www.fischland-darss-zingst.net/born/museen/borner-forstmuseum-jagdmuseum.php" target="_blank" class="external-link-new-window">Borner Forst- und Jagdmuseum</a></li><li><a href="http://www.kiten-lernen.de/windsurfschule.php">Windsurfen, Kitesufen und Kanuverleih in Born</a></li></ul>
          </b-col>
          <b-col cols="12" sm="6">
            <p>Neben dem Ort Ahrenshoop mit seinen Galerien und Restaurants bietet die nahe Umgebung interessante Ausflugsziele. Eine Auswahl von Sehenswürdigkeiten finden Sie hier:</p><ul class="linkedList"><li><a href="http://www.fischland-darss-zingst.net/fischland-darss-zingst/radtouren-wanderwege.php" target="_blank" class="external-link-new-window">Radwanderungen</a></li><li><a href="http://www.aquadrom.net/" target="_blank" class="external-link-new-window">Aquadrom in Gral-Müritz</a></li><li><a href="http://www.ozeaneum.de/" target="_blank" class="external-link-new-window">Ozeaneum in Stralsund</a></li><li><a href="http://www.deutsches-bernsteinmuseum.de/" target="_blank" class="external-link-new-window">Deutsches Bernsteinmuseum in Riebnitz-Damgarten</a></li><li><a href="http://www.karls.de/" target="_blank" class="external-link-new-window">Karl's Erlebnishof in Rövershagen</a></li><li><a href="http://www.straussenhof.de/" target="_blank" class="external-link-new-window">Straussenhof Neu Heinde</a></li></ul>
          </b-col>
        </b-row>

    </b-container>
</template>

<script>
  export default {
    components: {
    }
  }
</script>