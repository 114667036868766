<template>
  <div id="subpage">
    <div class="headerbackground"></div>
    <div id="wrap">
      <subheader/>
      <b-container>
        <b-row class="row">
          <b-col cols="12" sm="6">
            <h3>Impressum</h3>
            <p><b>Betreiber:</b>
            </p>
            <p>Inge Paulus<br>Fuchsienweg 7<br>12357 Berlin
            </p>
            <p>Telefon: +49-30-6621740<br>Telefax: +49-30-66623503<br>E-Mail:&nbsp;<a href="mailto:info@ferien-im-landhaus.de">info(at)ferien-im-landhaus(dot)de</a>
            </p>
            <p><b>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV (V.i.S.d.P.):</b>
            </p>
            <p>Inge Paulus<br>Fuchsienweg 7<br>12357 Berlin
            </p>
            <h3>Urheber- und Kennzeichenrecht</h3>
            <p>Der Betreiber. ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Fotos, Tondokumente, Animationen, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Fotos, Tondokumente, Animationen, Videosequenzen und Texte oder auf lizenzfreie Grafiken, Fotos, Tondokumente, Animationen, Videosequenzen und Texte zurückzugreifen.<br><br>Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!<br><br>Das Copyright für veröffentlichte, vom Betreiber selbst erstellte Objekte bleibt allein beim Betreiber. Eine Vervielfältigung oder Verwendung solcher Grafiken, Fotos, Tondokumente, Animationen, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung vom Betreiber nicht gestattet.
            </p>
            <h3>Inhalt des Internetangebotes</h3>
            <p>Der Betreiber übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Betreiber, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens vom Betreiber kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
            </p>
            <h3>Verweise und Links</h3>
            <p>Bei direkten oder indirekten Verweisen auf fremde Internetseiten (sogenannte "Hyperlinks"), die außerhalb des Verantwortungsbereiches des Betreibers liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Betreiber von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.
            </p>
            <p>Der Betreiber erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat der Betreiber keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller verlinkten / verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Betreiber eingerichteten Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen anderen Formen von Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
            </p>
            <h3><b>Rechtswirksamkeit dieses Haftungsausschlusses</b></h3>
            <p>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.<br><br></p>
          </b-col>
          <b-col cols="12" sm="6">
            <div class="fewo-imageslider" v-if="!isTablet">
              <div class="">
                <slider activeGallery="impressum"/>
              </div>
            </div>
            <lightbox activeGallery="impressum" v-if="isTablet"/>
          </b-col>
        </b-row>
      </b-container>
      <div class="pagebackground"></div>
    </div>
  </div>
</template>

<script>
import { checkForTablet } from '~/assets/js/utils';
import subheader from "~/components/page/header/subheader";
import slider from "~/components/content/fewoSlider/slider.vue";
import lightbox from "~/components/content/fewoLightbox/lightbox.vue";

export default {
  components: {
    subheader,
    slider,
    lightbox
  },
  data() {
    return {
      isTablet: false,
    }
  },

  mounted() {
    let _scope = this;

    this.handleResponsiveBehaviour();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        _scope.handleResponsiveBehaviour();
      }, 100);
    }, {passive: true});
  },

  methods: {
    handleResponsiveBehaviour () {
      this.isTablet = checkForTablet();
    }
  }
}
</script>
