<template>
    <div class="fewo-imagegallery" >
        <div class="image-item" @click="openGallery(0)">
            <picture>
                <img v-lazy="this.gallery[0].src" :alt="this.gallery[0].title"/>
            </picture>
            <a href="#"><img src="/img/full-screen.svg" width="40" height="40" alt="Full Screen"/></a>
        </div>
        <client-only>
            <light-box ref="lightbox" :show-light-box="false" :show-caption="true" :images="gallery" />
        </client-only>
    </div>
</template>

<script>
    import { elementExisits } from '~/assets/js/utils';

    export default {
        name: "lightbox",
        props: ['activeGallery'],

        data() {
            return {
                gallery: this.$store.state.fewoDatas[this.$props.activeGallery]
            }
        },

        methods: {
            openGallery(index) {
                this.$refs.lightbox.showImage(index);
            }
        }
    }
</script>