<template>
  <div id="fewo">
    <div class="headerbackground"></div>
    <div id="wrap">
      <fewoheader/>
      <headerimages/>
      <house/>
      <fewo/>
      <location/>
      <arrival/>
      <price/>
      <booking/>
      <fewofooter/>
    </div>
    <div class="pagebackground"></div>
  </div>
</template>

<script>
  import fewoheader from "../components/page/header/main.vue"
  import fewofooter from "../components/page/footer/main.vue"

  import headerimages from "../components/content/headerimages.vue"
  import house from "../components/content/house.vue"
  import fewo from "../components/content/fewo.vue"
  import location from "../components/content/location.vue"
  import arrival from "../components/content/arrival.vue"
  import price from "../components/content/price.vue"
  import booking from "../components/content/booking.vue"

  export default {
    components: {
      fewoheader,
      headerimages,
      house,
      fewo,
      location,
      arrival,
      price,
      booking,
      fewofooter,
    },
  }
</script>
