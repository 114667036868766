<template>
    <b-container id="arrival">
        <h2>Anreise zum Ostseebad Ahrenshoop</h2>

        <b-row class="row">
          <b-col cols="12" sm="6">
            <p>Von Hamburg und Berlin ist Ahrenshoop in zwei bis drei Stunden schnell zu erreichen.
            </p>
            <h3>Mit dem Auto</h3>
            <p>Sie fahren Sie aus Richtung Berlin/Hamburg kommend die A19 bis Rostock Ost, dann die B105 Richtung Stralsund bis Altheide. Am Ende des Ortes biegen Sie nach links in Richtung <b>Fischland </b>ab. Nach ca. 18 km erreichen Sie <b>Ahrenshoop - Ortsteil Althagen</b>. Das <b>Ahrenshooper Landhaus</b> befindet sich auf der rechten Straßenseite, wenn Sie <b>in Richtung Hafen abbiegen</b>.</p>
          </b-col>
          <b-col cols="12" sm="6">
            <h3>Mit Bus &amp; Bahn</h3>
            <p>Bequem ist auch die Anreise mit der <b>Eisenbahn</b>. Mit dem IC geht es im Handumdrehen nach Rostock und weiter mit dem D-Zug bis <b>Ribnitz-Damgarten</b>; von dort verkehrt ein <b>regelmäßiger Bustransfer nach Ahrenshoop</b>. Die Haltestelle befindet sich in unmittelbarer Nähe der Wohnung.
            </p>
            <p>Auf Wiedersehen in Ahrenshoop! Wir wünschen Ihnen eine gute Anreise und heißen Sie herzlich willkommen.</p>
          </b-col>
        </b-row>

    </b-container>
</template>

<script>
  export default {
    components: {
    }
  }
</script>