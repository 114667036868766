<template>
  <header id="header" ref="header">
    <div id="headerContent">
      <h1>Ferien im Landhaus</h1>
      <div class="imprintNav">
        <a href="/">
          <div class="homeLink">Zur Startseite</div>
          <div class="home"></div>
        </a>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  components: {},

  data() {
    return {
    }
  },
}
</script>
