<template>
  <header id="header" ref="header">
    <div id="headerContent" ref="headerContent">
      <div class="logo"></div>
      <h1>Ferien im Ahrenshooper Landhaus</h1>
    </div>
    <div class="navigation" ref="headernav">
      <input type="checkbox" id="openCloseMenue"/>
      <label class="openCloseMenue" for="openCloseMenue">
        <span class="open">Menü</span>
        <span class="close">Menü schließen</span>
      </label>
      <div id="nav" class="clearfix">
        <scrollactive class="my-nav" :offset="scrollOffset">
          <ul>
            <li><a href="#house" class="scrollactive-item house">Landhaus</a></li>
            <li><a href="#facility" class="scrollactive-item facility">Einrichtung</a></li>
            <li><a href="#location" class="scrollactive-item location">Umgebung</a></li>
            <li><a href="#arrival" class="scrollactive-item arrival">Anreise</a></li>
            <li><a href="#price" class="scrollactive-item price">Preise</a></li>
            <li><a href="#booking" class="scrollactive-item booking">Landhaus buchen</a></li>
          </ul>
        </scrollactive>
      </div>
    </div>
  </header>
</template>

<script>

import { getWindowInnerWidth, elementExisits } from '~/assets/js/utils';
import { breakpoints } from '~/assets/js/variables';

export default {
  components: {},

  data() {
    return {
      activeSection: 'house',
      activeSectionScroll: '',
      scrollOffset: 0,
    }
  },

  mounted: function () {
    if (elementExisits(this.$refs.header) && elementExisits(this.$refs.headernav) && elementExisits(this.$refs.headerContent) ) {
      this.onResize();
      window.addEventListener('resize', this.onResize, {passive: true});
      window.addEventListener('scroll', this.onResize, {passive: true});
    }
  },

  methods: {
    onResize() {
      if(this.$refs.header && this.$refs.headernav) {

        this.header = this.$refs.header;
        this.headerHeight = this.$refs.header.offsetHeight;

        this.headernav = this.$refs.headernav;
        this.headerContentHeight = this.$refs.headerContent.offsetHeight;

        this.scrollOffset = this.headerHeight + 0;

        this.onStickyHeader();
      }
    },
    onStickyHeader() {
      this.pageOffset = this.headerContentHeight + 0;

      if (window.pageYOffset > this.pageOffset) {
        this.header.classList.add("sticky");
      } else {
        if (this.header.classList.contains("sticky")) {
          this.header.classList.remove("sticky");
        }
      }

    }
  }
}
</script>
